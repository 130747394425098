export function GetSessionFlag(name: string) {
    const value = sessionStorage.getItem(name) ? true : false;
    return value;
};
export function ClearSessionFlag(name: string) {
    return sessionStorage.removeItem(name);
};
export function SetSessionFlag(name: string) {
    return sessionStorage.setItem(name, "1");
};

// reset stuff
export function ResetSessionFlags() {
    ClearSessionFlag("formChangesAllowNavigation");
    ClearSessionFlag("formChangesPending");
}

// fake slower bandwidth
export function IsSlowModeEnabled() {
    return GetSessionFlag("debugSlowMode");
};

export function SetFormChangesPending(value: boolean) {
    if (value === true) { 
        SetSessionFlag("formChangesPending");
    } else {
        ClearSessionFlag("formChangesPending");
    }
};

// export function SetFormChangesAllowNavigation() {
//     sessionStorage.setItem("formChangesAllowNavigation", "1");
// };
export function AreFormChangesPending() {
    const value = GetSessionFlag("formChangesPending");

    if (value) {
        const allowNavigation = GetSessionFlag("formChangesAllowNavigation");

        if (allowNavigation) {
            ClearSessionFlag("formChangesAllowNavigation");
            return false;
        }
    }

    // there is an override possible here
    return value;
};

export function ArmaReforger_SetServiceExperimental(serverid: string, value: boolean) {
    if (value) {
        SetSessionFlag(`cache:ArmaReforger:Experimental:${serverid}`);
    } else {
        ClearSessionFlag(`cache:ArmaReforger:Experimental:${serverid}`);
    }
};

export function ArmaReforger_IsServiceExperimental(serverid: string) {
    return GetSessionFlag(`cache:ArmaReforger:Experimental:${serverid}`);
};