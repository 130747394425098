import { CreateModal, DynamicModalInput } from "@/components/DynamicModal";
import { BackendResponse, backendSend } from "@/hooks/useBackend";
import { FiSave, FiRotateCcw, FiSkipBack, FiAlertOctagon, FiAlertTriangle } from "react-icons/fi";
import { DisplayToast } from "@/backend/toasts";
import { Message } from "@/components/Messages";
import { Button } from "@nextui-org/react";
import { SetFormChangesPending } from "./sessionStorage";

// interface GenericSetting
export interface FormChanges {
    changes: {
        [key: string]: any
    };
    blockInput: boolean;
    invalid: Set<string>;
}

export function DefaultFormChanges(): FormChanges {
    return {
        changes: {},
        invalid: new Set<string>([]),
        blockInput: false,
    }
}

// a bunch of helpers for doing forms stuff
interface DefaultsModalProps {
    settings?: any;
    formState: any;
    formStateSetter: Function;
}

export interface InputData {
    name: string;
    value: any;
    isValid: boolean;
}

interface InputChangedProps extends DefaultsModalProps {
    inputData: InputData;
    settingsMode?: boolean;
    allowNavigation?: boolean;
}

interface FormSubmitProps {
    submitEndpoint: string;
    formState: FormChanges;
    settings?: any;
    setFormState: Function;
    submitFull?: boolean;
    fullCurrentData?: any;
    onBeforeSubmit?: Function;
    submitCallback?: Function;
    revertCallback?: Function;
}

export function OnNavigationStarted(data: any, extra: any) {
    console.log("hello", data, extra);
}

export function HandleRevert(props: FormSubmitProps) {
    props.setFormState(DefaultFormChanges());
    SetFormChangesPending(false);
    if (props.revertCallback) { props.revertCallback(); }
}

export function HandleSubmit(props: FormSubmitProps) {
    function OnResponse(response: BackendResponse) {
        if (response.success) {
            DisplayToast({ style: "success", header: "Ok!", message: "Settings applied" })
            SetFormChangesPending(false);
            // setTimeout(props.setFormState, 1000, DefaultFormChanges());
            
            if (props.settings) {
                Object.keys(props.formState.changes).map(function(item: any) {
                    props.settings[item].value = props.formState.changes[item];
                });
            };

            props.setFormState(DefaultFormChanges());
        } else {
            DisplayToast({ style: "error", header: "Failed", message: "Sorry, failed to apply: "+response.reason })
            // setTimeout(props.setFormState, 1000, { ...props.formState, blockInput: false });
            props.setFormState({ ...props.formState, blockInput: false });
        }

        if (props.submitCallback) { props.submitCallback(response); }
    };

    props.setFormState({ ...props.formState, blockInput: true });

    let blob = structuredClone(props.formState.changes);
    if (props.submitFull && props.fullCurrentData) {
        blob = { ...props.fullCurrentData, ...blob }
    }

    if (props.onBeforeSubmit) { props.onBeforeSubmit(); }

    backendSend({ endpoint: props.submitEndpoint, method: 'PATCH', json: blob, responseCallback: OnResponse })
}

// compare value with settings value
function IsDifferent(wantedValue: any, settingsValue: any) {
    let comp = settingsValue;
    if (settingsValue.hasOwnProperty('label') && settingsValue.hasOwnProperty('description')) {
        comp = settingsValue.value;
    }

    if (typeof(wantedValue) === "object") {
        return JSON.stringify(wantedValue) !== JSON.stringify(comp);
    }

    return wantedValue !== comp;
}

// handle update in generic form
export function HandleInputChanged(props: InputChangedProps) {
    const copyChanges = structuredClone(props.formState);

    copyChanges.changes[props.inputData.name] = props.inputData.value;

    if (!props.inputData.isValid) {
        copyChanges.invalid.add(props.inputData.name);
    } else {
        copyChanges.invalid.delete(props.inputData.name);
    };

    if (props.settings) {
        if (!IsDifferent(props.inputData.value, props.settings[props.inputData.name])) {
            delete(copyChanges.changes[props.inputData.name]);
        }
    };

    if (!props.allowNavigation) {
        if (Object.keys(copyChanges.changes).length < 1) {
            SetFormChangesPending(false);
        } else {
            SetFormChangesPending(true);
        }
    }

    props.formStateSetter(copyChanges);
};

function FormatDefaultValue(value: any) {
    if (typeof(value) === "boolean") {
        return value ? "Enabled" : "Disabled"
    };
   
    if (typeof(value) === "object") {
        if (Array.isArray(value)) {
            return `${value.length} item${value.length > 1 ? "s" : ""}`;
        }

        return "(too long to display)";
    };

    const asString = value.toString();

    if (asString === "") {
        return "(empty)";
    };

    if (asString.length > 32) {
        return asString.substring(0, 32) + "...";
    }

    return asString;
}

// create a reset to defaults dialog from a form
export function CreateApplyDefaultsModal(props: DefaultsModalProps) {
    let defaultsInputs: DynamicModalInput[] = [];

    // @ts-ignore
    for (const [key, value] of Object.entries<any, any>(props.settings)) {
        // only if setting has a default
        if (value.hasOwnProperty("default")) {
            defaultsInputs.push(
                {
                    inputType: "checkbox",
                    inputName: key,
                    inputLabel: value.label,
                    inputDescription: "default: " + FormatDefaultValue(value.default),
                    initialValue: false,
                }
            )
        }
    }

    defaultsInputs.sort(function(a: DynamicModalInput, b: DynamicModalInput) { return a.inputLabel.localeCompare(b.inputLabel); });

    defaultsInputs.unshift({
        inputType: "checkbox",
        inputName: "_select_all",
        inputLabel: "Select All",
        inputDescription: "",
        initialValue: false,
    });

    function ApplySelectedDefaults(data: any, inputs: FormChanges) {
        const copyChanges = structuredClone(props.formState);

        for (const [key, value] of Object.entries(inputs.changes)) {
            if (key === "_select_all") {
                continue;
            };
            // props.settings[key].value = props.settings[key].default;
            copyChanges.changes[key] = props.settings[key].default;
        };

        copyChanges.invalid = new Set([...props.formState.invalid, ...inputs.invalid]);
        props.formStateSetter(copyChanges);
    }

    CreateModal({ 
        title: "Apply Defaults", 
        message: "Please select fields to reset to default",
        icon: <FiRotateCcw />,
        callback: ApplySelectedDefaults,
        inputs: defaultsInputs
    });
}

export function FormApplyChangesButton(props: FormSubmitProps) {
    const isVisible = Object.keys(props.formState.changes).length > 0;

    return (
        <div className={`${isVisible ? "fixed" : "hidden"} w-full bottom-0 z-30 bg-content1 p-4`} 
            style={{ right: "0px", minHeight: "64px", boxShadow: "0px -10px 15px 0px hsl(var(--nextui-content1))", animation: "savebutton-slide-in 0.5s forwards", transform: "translateY(200%)" }}>
            <div className="flex flex-row justify-between items-center gap-2">

                    <div className="flex">
                    {
                        props.formState.invalid.size > 0 ? <div className="text-danger"><FiAlertOctagon /></div> :
                        Object.keys(props.formState.changes).length > 0 && <div className="text-warning"><FiAlertTriangle /></div>
                    }
                    </div>
                    <div className="flex flex-row flex-grow w-auto justify-end gap-2">
                        <div className={`${Object.keys(props.formState.changes).length > 0 ? "flex" : "hidden"}`}>
                            <Button className="button-action" 
                                color="warning" 
                                variant="light"
                                isDisabled={false}
                                onPress={() => HandleRevert(props)}
                                startContent={<FiSkipBack />}>Revert</Button>
                        </div>
                        <div className={`${props.formState.invalid.size > 0 ? "hidden" : "flex"}"`}>
                            <Button className="button-action" 
                                color="warning" 
                                isDisabled={props.formState.blockInput ? true : props.formState.invalid.size > 0 ? true : Object.keys(props.formState.changes).length < 1 ? true : false}
                                onPress={() => HandleSubmit(props)}
                                startContent={<FiSave />}>Save</Button>
                        </div>
                </div>
            </div>
        </div>
    );
}