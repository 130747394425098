import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BackendResponse } from '@/hooks/useBackend';

export interface ToastData {
    style: string;
    header: string;
    message: string;
}

export interface ToastParams {
    headerFailed: string;
    messageFailed: string;
    headerSuccess: string;
    messageSuccess: string;
}

// export function showToast(header: string | undefined, message: string | undefined, which: Function) {
//     const ToastBody = ( header: any, message: any ) => (
//         <div className="toastDiv">
//             <h6>{header}</h6>
//             <p>{message}</p>
//         </div>
//     )

//     which(ToastBody(header, message), { autoClose: 4000 })
// }

function GetToastHandler(style: string) {
    switch (style) {
        case "success":
            return toast.success;
        case "error":
            return toast.error;
    };

    return toast.info;
}

export function DisplayToast(data: ToastData) {
    const handler = GetToastHandler(data.style);

    const body = <div className="toastDiv flex gap-2 flex-col">
                    <div className="text-[12px] uppercase font-bold toast-header" style={{ paddingTop: "2px" }}>{data.header}</div>
                    <div className="text-xs">{data.message}</div>
                </div>

    handler( body, {autoClose: 3500} );
}

export function DisplayToastFromBackendResponse(response: BackendResponse, params: ToastParams) {
    if (!response.success) {
        DisplayToast({ style: "error", header: params.headerFailed, message: `${params.messageFailed}: ${response.reason} (code ${response.status})` });
        return;
    }

    DisplayToast({ style: "success", header: params.headerSuccess, message: params.messageSuccess });
}