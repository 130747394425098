// import { Card, Text, Container, StyledCardFooter } from "@nextui-org/react";

import { Card, CardHeader, CardBody } from "@/components/Layout/Card";
import { Divider } from "@nextui-org/react";

import * as Sentry from "@sentry/browser";

import React, { Component, ErrorInfo, ReactNode } from "react";
// import Icon from "./Icon";

import { FiAlertCircle } from "react-icons/fi";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    error: any;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null
    };

    public static getDerivedStateFromError(err: Error): State {
        return { hasError: true, error: err };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
        Sentry.captureException(error);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div className="flex justify-center mt-8">
                    <Card className="max-w-[800px] m-4">
                        <CardHeader className="flex justify-start content-start text-danger">
                            <div className="flex flex-row items-center gap-2">
                                <FiAlertCircle size={16} />
                                <div>An unknown error has occurred</div>
                            </div>
                        </CardHeader>
                        <Divider />
                        <CardBody className="flex justify-start gap-4 text-xs">
                            <div>The app has encountered an unknown error.</div>
                            <div>
                                <div>The error has likely been logged. If you keep seeing this there are a few possibilities:</div>
                                <ol className="list-decimal list-inside">
                                    <li>There is a connection problem between the app and the server</li>
                                    <li>Something in the app is broken</li>
                                </ol>
                            </div>
                            <div>If you keep seeing this, please report it!</div>
                            { this.state.error !== null && <Divider /> }
                            <div className="whitespace-pre-line text-danger">{ this.state.error !== null && this.state.error.toString() }</div>
                        </CardBody>
                    </Card>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;