import { 
    CardProps, 
    Card as NextUICard, 
    CardHeader as NextUICardHeader, 
    CardBody as NextUICardBody,
    CardFooter as NextUICardFooter,
    Divider,
} from "@nextui-org/react";
import { ReactNode } from "react";

export function Card(props: CardProps) {
    const classNames = props.className ?? "";

    return <NextUICard {...props} className={classNames + " layout-card"} />
}

export function CardHeader(props: any) {
    const classNames = props.className ?? "";

    return <NextUICardHeader {...props} className={classNames + " flex flex-wrap justify-between card-header-panel"} />
}

export function CardBody(props: any) {
    const classNames = props.className ?? "";

    return <NextUICardBody {...props} className={classNames + " gap-4"} />
}

export function CardFooter(props: any) {
    return <NextUICardFooter {...props} />
}

interface PanelProps {
    header: string;
    children: ReactNode;
    bodyClassName?: string;
    footer?: JSX.Element;
    cardClassName?: string;
}

export function Panel(props: PanelProps) {
    return (
        <Card fullWidth className={props.cardClassName}>
            <CardHeader>
                <p>{props.header}</p>
            </CardHeader>
            <Divider />
            <CardBody className={props.bodyClassName}>
                {props.children}
            </CardBody>
            {
                props.footer && <CardFooter>
                    {props.footer}
                </CardFooter>
            }
        </Card>
    )
}