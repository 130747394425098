import { DisplayToast } from "./toasts";


export interface Notification {
    level: "info" | "error" | "warning" | "success";
    title: string;
    content: string;
    timestamp: number;
}

interface NotificationStorage {
    addHandler: null | Function;
    removeHandler: null | Function;
    setHandler: null | Function;
    notifications: Notification[];
}

export const notificationStorage: NotificationStorage = { 
    addHandler: null,
    removeHandler: null,
    setHandler: null,
    notifications: []
};

import React from 'react';
const NotificationContext = React.createContext<Notification[]>([]);
export const NotificationContextProvider = NotificationContext.Provider;
export default NotificationContext;

interface NotificationSubscriberMap {
    [key: string]: Function;
}

const notificationSubscribers: NotificationSubscriberMap = {};

export function AddNotificationSubscriber(name: string, callback: Function) {
    notificationSubscribers[name] = callback;
}

export function AddNotifications(data: any) {
    if (notificationStorage.setHandler === null) {
        console.error("Tried to add a notification before handler initialized.");
        return;
    };

    const arr = structuredClone(notificationStorage.notifications);

    // for (let i = 0; i < 10; i++) {
        data.map(function(item: any) {
            // notify observers
            Object.keys(notificationSubscribers).map(function(name: string) {
                if (notificationSubscribers[name]) {
                    notificationSubscribers[name](item);
                }
            });

            arr.unshift(
                {
                    level: "info",
                    title: item.title,
                    content: `${item.initiator}: ${item.serverid} - ${item.content}`,
                    timestamp: Date.now()/1000
                }
            );
        
            if (arr.length > 99) {
                arr.splice(99, 1);
            };
        });
    // };

    notificationStorage.setHandler(arr);
}

// export function AddNotification(data: any) {
//     if (notificationStorage.setHandler === null) {
//         console.error("Tried to add a notification before handler initialized.");
//         return;
//     };

//     const arr = structuredClone(notificationStorage.notifications);

//     arr.unshift(
//         {
//             level: "info",
//             title: data.title,
//             content: `${data.initiator}: ${data.serverid} - ${data.content}`
//         }
//     );

//     if (arr.length > 99) {
//         arr.splice(99, 1);
//     };

//     notificationStorage.setHandler(arr);
// }

export function RemoveNotification(idx: any) {
    notificationStorage.notifications.splice(idx, 1);
}

export function DisplayNotification(data: any) {
    if (data.status !== 1) {
        return;
    }

    DisplayToast({
        style: "info",
        header: data.title,
        message: `${data.initiator}: ${data.serverid} - ${data.content}`
    });

    Object.keys(notificationSubscribers).map(function(name: string) {
        if (notificationSubscribers[name]) {
            notificationSubscribers[name](data);
        }
    });
};