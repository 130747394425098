import { Badge, Button, Popover, PopoverTrigger, PopoverContent, Divider, ScrollShadow, Link } from "@nextui-org/react";
import { FiMessageCircle, FiXCircle } from "react-icons/fi";

import { Notification } from "@/backend/notifications";
import { useState } from "react";

function NotificationComponent(props: any) {
    const tsDiffSeconds = props.timestamp - props.tsNow;

    return (
        <div className="flex flex-row justify-between border-l-2 border-primary pl-1 gap-2 group">
            <Button 
                className="flex items-start flex-col w-auto h-auto pt-[2px] pb-[2px] pl-[8px] pr-[8px] w-full whitespace-normal"
                
                disableRipple
                variant="light"
                onPress={() => { setTimeout(props.removeFunction, 200, props.index) }}>
                <div className="flex flex-col items-start text-left">
                    <div className="text-[11px] font-bold uppercase">{props.title}</div>
                    <div className="text-xs">{props.content}</div>
                    <div className="text-[10px] italic">{tsDiffSeconds > -61 ? "Just now" : props.rtf.format(Math.ceil(tsDiffSeconds/60), 'minutes')}</div>
                </div>
            </Button>
        </div>
    )
}

export function NotificationsComponent(props: any) {
    const [ isOpen, setOpen ] = useState(false);

    const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'long' });
    const tsNow = Date.now()/1000;
    
    function RemoveNotification(idx: number) {
        if (idx === -1) {
            return props.setNotifications([]);
        }

        const arr = structuredClone(props.notifications);

        arr.splice(idx, 1);

        props.setNotifications(arr);
    };

    // <Button 
    //                     isIconOnly
    //                     className="button-nohover text-inherit overflow-visible"
    //                     variant="light">
    //                     <Badge 
    //                         isInvisible={props.notifications.length === 0}
    //                         content={props.notifications.length} color="primary" size="sm">
    //                             <FiMessageCircle size={20} />
    //                     </Badge>
    //                 </Button>

    return (
        <div className="text-inherit">
            <Popover 
                backdrop="opaque"
                placement="bottom-start" 
                isOpen={isOpen} 
                onOpenChange={(open) => setOpen(open)}
                classNames={{ base: [ "w-full sm:max-w-[300px]" ]}}>
                <PopoverTrigger>
                    <Link href="#" className="text-inherit overflow-visible pl-2 pr-2">
                        <Badge 
                            isInvisible={props.notifications.length === 0}
                            content={props.notifications.length} color="primary" size="sm">
                                <FiMessageCircle size={20} />
                        </Badge>
                    </Link>
                </PopoverTrigger>
                <PopoverContent className="w-full">
                    <div className="w-full min-w-[230px] flex flex-col justify-start">
                        <div className="flex flex-row justify-between pt-2 pb-2">
                            <div className="text-sm font-semibold flex flex-row justify-start gap-2 items-center">
                                <FiMessageCircle />
                                Activity
                            </div>
                            <div>
                                {
                                    props.notifications.length > 0 &&
                                    <Button 
                                        className="button-action-tiny" 
                                        onPress={() => { setTimeout(RemoveNotification, 100, -1) }}
                                        variant="light"
                                        color="danger" endContent={<FiXCircle size={16} />}>Dismiss All</Button>
                                }
                            </div>
                        </div>
                        <Divider />
                        <ScrollShadow className="max-h-[600px] flex gap-2 pt-2 pb-2 pr-4 flex-col">
                        {
                            props.notifications.length < 1 ?
                                <div>Actions executed by other users will appear here.</div>
                            : isOpen ?
                                props.notifications.map(function(item: Notification, index: number) {
                                    return (
                                        <NotificationComponent key={`notification_${index}`} { ...item } index={index} removeFunction={RemoveNotification} rtf={rtf1} tsNow={tsNow} />
                                    )
                                })
                            : <></>
                        }
                        </ScrollShadow>
                    </div>
                </PopoverContent>
            </Popover>
            
        </div>
    )
}