import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const useSafePush = () => {
  const [onChanging, setOnChanging] = useState(false);
  // const handleError = () => function(data: any) {
  //   console.log(data);
  //   setOnChanging(false);
  // };
  const handleRouteChange = () => {
    setOnChanging(false);
  };
  const router = useRouter();
  // safePush is used to avoid route pushing errors when users click multiple times or when the network is slow:  "Error: Abort fetching component for route"
  const safePush = (path: string) => {
    if (onChanging) {
      return;
    }
    setOnChanging(true);
    router.push(path);
  };

  useEffect(() => {
    router.events.on('routeChangeError', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeError', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, setOnChanging]);
  return { safePush };
};

export default useSafePush;

// router.events.on('routeChangeStart', OnNavigationStarted);
	 
// 		// If the component is unmounted, unsubscribe
// 		// from the event with the `off` method:
// 		return () => {
// 			router.events.off('routeChangeStart', OnNavigationStarted);
// 		}