import { browserVersion, browserName, engineName } from 'react-device-detect';

interface BrowserVersions {
    [key: string]: number;
}
const minimumVersions: BrowserVersions = {
    "Chrome": 117,
    "Edge": 117,
    "Opera": 102,
    "Safari": 17.0,
    "Mobile Safari": 16.5,
    "Firefox": 117,
    "Internet Explorer": 11
}

function IsOutdated() {
    const currentVersion = Number(browserVersion);

    if (engineName === "Blink") {
        if (currentVersion >= minimumVersions.Chrome) {
            return [ false, "", "" ];
        }
    }

    if (!minimumVersions.hasOwnProperty(browserName)) {
        return [ true, "Unsupported browser", `Your browser ${browserName} ${browserVersion} is not supported. In case of issues or bugs, try latest versions of Chrome.` ];
    }

    if (Number.isNaN(currentVersion)) {
        return [ true, "Unsupported browser version", `Your browser ${browserName} ${browserVersion} is not supported. In case of issues or bugs, try latest versions of Chrome.` ];
    }

    if (currentVersion < minimumVersions[browserName]) {
        return [ true, "Outdated browser", `Your browser ${browserName} ${browserVersion} may be outdated. In case of issues or bugs, try updating to the latest version.` ];
    }

    return [ false, "", "" ];
}

export default function OutdatedBrowserWarning(props: any) {
    const [ showWarning, warningTitle, warningMessage ] = IsOutdated();

    return (
        <>
            {
                showWarning && 
                    <div className="p-2 text-warning bg-[#000a16]">
                        <div className="text-[11px] font-bold uppercase">{warningTitle}</div>
                        <div className="text-[11px] font-semibold uppercase">{warningMessage}</div>
                    </div>
            }
        </>
    )
}