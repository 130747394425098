import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Divider} from "@nextui-org/react";
import { useState } from "react";
import { InputField, CheckboxField } from "./InputField";
import { DefaultFormChanges, FormChanges, HandleInputChanged, InputData } from "@/backend/FormHandlers";
import { FiCheck, FiInfo, FiX } from "react-icons/fi";

interface ModalCallbackParams {
    [key: string]: any
}

export interface DynamicModalInput {
    inputType: "checkbox" | "text";
    inputName: string;
    inputLabel: string;
    inputDescription?: string;
    initialValue: any;
    // for text input
    validationRegex?: string;
    validationHint?: string;
    // state
    disabled?: boolean;
}

interface ModalInputState {
    data: {
        [key: string]: string
    },
    invalid: Set<string>;
}

export interface DynamicModalProps {
    // content
    title: string;
    icon: JSX.Element;
    message?: string;
    content?: JSX.Element;
    // dynamic inputs
    inputs?: DynamicModalInput[];
    // is confirm button blocked
    blocked?: boolean;
    // callback
    callback?: Function;
    callbackParams?: ModalCallbackParams;
    buttonText?: string;
    allowEmptyForm?: boolean;
    // state
    // modalVisible?: boolean;
    // setModalVisibleHandler?: Function;
}

interface SelectAllCheckboxesProps {
    formState: FormChanges;
    setFormState: Function;
    inputs: DynamicModalInput[];
    setTo: boolean;
}

function SelectAllInputs(props: SelectAllCheckboxesProps) {
    const newForm = DefaultFormChanges();

    for (const item of props.inputs) {
        if (item.inputType === "checkbox") {
            if (item.disabled) {
                continue;
            }

            newForm.changes[item.inputName] = props.setTo;
        }
    }

    props.setFormState(newForm);
}

interface ModalHandler {
    setModalVisible: Function;
    setModalData: Function;
}

let globalModal: any | ModalHandler = null;

export function RegisterModal(visibleHandler: Function, dataHandler: Function) {
    globalModal = { setModalVisible: visibleHandler, setModalData: dataHandler };
}
export function CreateModal(data: DynamicModalProps) {
    if (!globalModal) {
        console.error("Cannot create a modal: Global modal is null");
        return;
    }

    globalModal.setModalData(data);

    setTimeout(globalModal.setModalVisible, 100, true);

    // globalModal.setModalVisible(true);
}

export function DynamicModal(props: any) {
    const [ modalOpen, setModalOpen ] = useState(false);
    // const [ modalInputState, setModalInputState ] = useState<ModalInputState>({ data: {}, invalid: new Set<string>([]) });

    const [ formState, setFormState ] = useState<FormChanges>(DefaultFormChanges());

	const [ modalData, setModalData ] = useState<DynamicModalProps>({
		// content
		title: "modal",
		icon: <FiInfo />,
		message: "message",
		// callback
		callback: console.log,
		callbackParams: {}
	});

    function NewModal(data: DynamicModalProps) {
        setModalData(data);
        setFormState(DefaultFormChanges());
    }

    RegisterModal(setModalOpen, NewModal);

    function CloseModal() {
        if (!setModalOpen) {
            return;
        }

        setModalOpen(false);
    };

    function OnSubmit() {
        if (modalData.callback) {
            modalData.callback(modalData.callbackParams, formState);
        }

        CloseModal();
    };

    function HandleInput(data: InputData) {
        if (data.name === "_select_all") {
            // @ts-ignore
            return SelectAllInputs({ formState: formState, setFormState: setFormState, inputs: modalData.inputs, setTo: data.value });
        } else {
            if (data.value === false) {
                formState.changes["_select_all"] = false;
                setFormState(formState);
            };
        };

        HandleInputChanged({
            inputData: data,
            formState: formState,
            formStateSetter: setFormState,
            allowNavigation: true
        });
    };

    function GenerateInputs() {
        if (!modalData?.inputs) {
            return <></>;
        }

        return modalData.inputs.map(function(item: DynamicModalInput) {
            return (
                <div key={item.inputName}>
                    {
                        item.inputType === "text" ?
                            <InputField 
                                name={item.inputName}
                                label={item.inputLabel}
                                value={formState.changes[item.inputName] ?? item.initialValue}
                                validationRegex={item.validationRegex}
                                validationHint={item.validationHint}
                                onInputChanged={HandleInput}
                                />
                        : 
                        <CheckboxField 
                            name={item.inputName}
                            label={item.inputLabel}
                            value={formState.changes[item.inputName] ?? item.initialValue}
                            isDefaultSelected={item.initialValue}
                            description={item.inputDescription}
                            onInputChanged={HandleInput}
                            disabled={item.disabled}
                            />
                    }
                </div>
            )
        });
    };

    const confirmButtonDisabled = () => {
        if (modalData.blocked === true) {
            return true;
        }

        if (!modalData.hasOwnProperty("inputs")) {
            return false;
        }
        
        // @ts-ignore
        if (modalData.inputs.length < 1) {
            return false;
        }

        return modalData.allowEmptyForm ? false : Object.keys(formState.changes).length < 1 ? true : formState.invalid.size > 0;
    }

    return (
        <Modal isOpen={modalOpen} isDismissable onClose={CloseModal} placement="center" scrollBehavior="outside">
            <ModalContent>
                <ModalHeader className="flex flex-row justify-start items-center gap-2 card-header-panel">
                    <div className="modal-icon">{modalData.icon}</div>
                    <p>{modalData.title}</p>
                </ModalHeader>
                <Divider />
                <ModalBody>
                    {
                        modalData.message && <div className="text-sm pt-4">{modalData.message}</div>
                    }
                    {modalData.content}
                    <div>
                        {GenerateInputs()}
                    </div>
                </ModalBody>
                { 
                    !modalData.callback ?
                        <ModalFooter>
                            <Button color="primary" onPress={CloseModal}>
                                {
                                    modalData.buttonText ?? "OK"
                                }
                            </Button>
                        </ModalFooter>
                    :
                    <ModalFooter>
                        <Button color="warning" variant="flat" className="button-action" startContent={<FiX />} onPress={CloseModal}>
                            Cancel
                        </Button>
                        <Button color="primary" onPress={() => OnSubmit()} className="button-action" startContent={<FiCheck />} isDisabled={confirmButtonDisabled()}>
                            Confirm
                        </Button>
                    </ModalFooter>
                }
            </ModalContent>
        </Modal>
    )
}
